import React, { useEffect, useState } from "react"
import LoadableVisibility from "react-loadable-visibility/react-loadable"
import LineChartLoader from "../../Loaders/LineChartLoader"
import { GraphContainer } from "../UIElements"

const Loading = _ => <LineChartLoader />

// Loadable components
const LineChartContent = LoadableVisibility({
  loader: () => import("./LineChartContent"),
  loading: Loading,
})

const LineChartItem = props => {
  const { isocode, ccaa, mode, showGuides } = props
  const [dataset, setDataset] = useState(null)
  const [categories, setCategories] = useState(null)

  const getMovingAvg = arr => {
    let newArr = []
    arr.forEach((item, idx, arr) => {
      const newItem =
        ((arr[idx - 3] || arr[idx + 3] || 0) +
          (arr[idx - 2] * 3 || arr[idx + 2] * 3 || 0) +
          (arr[idx - 1] * 5 || arr[idx + 1] * 5 || 0) +
          item * 7 +
          (arr[idx + 1] * 5 || arr[idx - 1] * 5 || 0) +
          (arr[idx + 2] * 3 || arr[idx - 2] * 3 || 0) +
          (arr[idx + 3] || arr[idx - 3] || 0)) /
        25
      newArr.push(newItem)
    })
    return newArr
  }

  useEffect(() => {
    const fetchingData = isocode => {
      fetch(`https://covid19.secuoyas.io/api/v1/es/ccaa/?codigo=${isocode}`)
        .then(response => response.json())
        .then(resultData => {
          const startDate = "2020-03-14"
          const startDateIdx = resultData.timeline.findIndex(item => item.fecha === startDate)
          const timeline = resultData.timeline.slice(startDateIdx)
          const data = timeline
            .map(dayData => {
              return dayData.regiones[0].data.casosConfirmados
            })
            .filter(el => el > 0)
          const dates = timeline.map((dayData, idx) => {
            return idx % 7 === 0
              ? new Date(dayData.fecha).toLocaleDateString("es-ES", {
                  month: "short",
                  day: "2-digit",
                })
              : ""
          })

          setDataset(getMovingAvg(data))
          setCategories(dates)
        })
        .catch(err => console.log("Error getting data from API: " + err))
    }

    fetchingData(isocode)
  }, [isocode])

  return (
    <GraphContainer p={["s", "20px"]} minHeight="350px">
      {dataset && categories && props && (
        <LineChartContent
          mode={mode}
          showGuides={showGuides}
          dataset={dataset}
          categories={categories}
          ccaa={ccaa}
        />
      )}
    </GraphContainer>
  )
}

export default LineChartItem
